import './style.css';

import ImageTile from 'ol/source/ImageTile.js';
import KML from 'ol/format/KML.js';
import Map from './node_modules/ol/Map.js';
import VectorSource from 'ol/source/Vector.js';
import OSM from './node_modules/ol/source/OSM.js';
import Overlay from './node_modules/ol/Overlay.js';
import TileLayer from './node_modules/ol/layer/Tile.js';
import {Vector as VectorLayer} from 'ol/layer.js';
import View from './node_modules/ol/View.js';
import {fromLonLat, toLonLat} from './node_modules/ol/proj.js';
import {toStringHDMS} from './node_modules/ol/coordinate.js';
import {format} from './node_modules/ol/coordinate.js';
import {useGeographic} from 'ol/proj.js';

useGeographic();

var coordinates = '';

const layer = new TileLayer({
  source: new OSM(),
});

const vector = new VectorLayer({
  source: new VectorSource({
    url: '../praxisstandorte.kml',
    format: new KML({
				showPointNames: false,
				extractStyles: true,
				extractAttributes: true
		}),
  }),
});

const map = new Map({
  layers: [layer, vector],
  target: document.getElementById('map'),
  view: new View({
    center: [8.4979, 50.646],
    projection: 'EPSG:3857',
    zoom: 10,
  }),
});


// Popup showing the position the user clicked
const popup = new Overlay({
  element: document.getElementById('popup'),
});
map.addOverlay(popup);

const element = popup.getElement();
map.on('click', function (evt) {
  const click_coordinate = evt.coordinate;
  popup.setPosition(click_coordinate);
  //popup.setPosition(coordinates);
  let popover = bootstrap.Popover.getInstance(element);
  if (popover) {
    popover.dispose();
  }
  
  const features = [];
  var name = '';
  var description = '';

  var out = '';

  map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    features.push(feature);
	name = feature.get('name');
	description = feature.get('description');
	coordinates = feature.getGeometry().getCoordinates();
	const template = '{y},{x}';
	out = format(coordinates, template, 6);
  },
  {
      hitTolerance: 5,
  },
  ); 
  
  popover = new bootstrap.Popover(element, { 
    animation: false,
    container: element,
    content: '<p>' + description + '</p><code>' + '<a target="_blank" href="https://www.google.com/maps/?q=' + out + '">Routenplanung</a>' + '</code>',
    html: true,
    placement: 'right',
    title: name,
  });

  if (features.length > 0) {
    popover.show();
  }
  
  displayFeatureInfo(evt.pixel);
});

// Feature Info
const displayFeatureInfo = function (pixel) {
  const features = [];
  map.forEachFeatureAtPixel(pixel, function (feature) {
    features.push(feature);
  });
  if (features.length > 0) {
    map.getTarget().style.cursor = 'pointer';
  } else {
    map.getTarget().style.cursor = '';
  }
};

map.getView().on('change:resolution', (event) => {
	popup.setPosition(coordinates);
    console.log("zoom changed");
});

map.on('pointermove', function (evt) {
  if (evt.dragging) {
    return;
  }
  const pixel = map.getEventPixel(evt.originalEvent);
  displayFeatureInfo(pixel);
});


